import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  ButtonPriority as ButtonPRIORITY,
  ButtonSize as ButtonSIZE,
} from 'wix-ui-tpa/cssVars';
import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import { Challenges } from '../../../../../editor/types/Experiments';
import utils, { getFlatStepsList } from '../../views/utils';

import { IControl } from './types';
import { isSectionLocked } from '../../../../../selectors/sections';
import { SidebarLayoutButton } from '../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { SidebarControlsContainer } from '../SidebarControlsContainer/SidebarControlsContainer';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useChallengeData } from '../../../../../contexts/storage-contexts/Challenge';

export interface ISectionControls extends IControl {
  currentSectionId: string;
  onStepChosen(firstSectionStep: ParticipantStep): void;
}

export const SectionControls: FCWithChildren<ISectionControls> = (props) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const chosenSection = props.sections.find(
    (s) => s.id === props.currentSectionId,
  );

  const isHideSection =
    experiments.enabled(Challenges.enableDripContent) &&
    isSectionLocked(chosenSection);

  if (isHideSection) {
    return null;
  }

  const flatSteps = getFlatStepsList({
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });

  const firstSectionStep = chosenSection?.steps?.[0];

  const isFirstSectionStepHidden =
    firstSectionStep &&
    utils.isStepHidden(challenge, flatSteps, firstSectionStep);

  const nextEntity = utils.getNextEntity(firstSectionStep?.id, {
    listParticipantSections: props.sections,
    participantSteps: props.steps,
    currentSection: chosenSection,
  });

  if (isFirstSectionStepHidden) {
    return null;
  }

  return (
    <SidebarControlsContainer>
      <SidebarLayoutButton
        fullWidth={isMobile}
        priority={ButtonPRIORITY.primary}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          if (firstSectionStep) {
            props.onStepChosen(firstSectionStep);
          } else {
            await props.onNextEntity(nextEntity);
          }
        }}
      >
        {t('challenge.page.steps.continue')}
      </SidebarLayoutButton>
    </SidebarControlsContainer>
  );
};
