import React from 'react';

import {
  Participant,
  ParticipantStep,
  ParticipantStepState,
} from '@wix/ambassador-challenges-v1-participant/types';

import {
  isStepAvailableForComplete,
  isStepOverdue,
  isStepPending,
  isStepRunning,
} from '../../components/ParticipantPage/Widget/views/utils';

import { CompletedIcon } from '../CompletedIcon';
import { MissedIcon } from '../MissedIcon';
import { LockedIcon } from '../LockedIcon';
import { RunningIcon } from '../RunningIcon';
import { FCWithChildren } from '@wix/challenges-web-library';
import { isUserCompleted } from '../../contexts/User/helpers/userTypeHandlers';

export interface IStepIcon {
  step: ParticipantStep;
  isUnavailable: boolean;
  participant: Participant;
}

const STEP_INDICATOR = {
  [ParticipantStepState.COMPLETED]: <CompletedIcon />,
  [ParticipantStepState.OVERDUE]: <MissedIcon />,
  [ParticipantStepState.PENDING]: <LockedIcon />,
  [ParticipantStepState.RUNNING]: <RunningIcon />,
  STEP_COMPLETION_DISABLED: <LockedIcon />,
};

export const StepIcon: FCWithChildren<IStepIcon> = (props) => {
  const { step, participant } = props;

  if (
    (isStepOverdue(step) || isStepRunning(step) || isStepPending(step)) &&
    isUserCompleted(participant?.transitions?.[0]?.state)
  ) {
    return STEP_INDICATOR.STEP_COMPLETION_DISABLED;
  }
  // eslint-disable-next-line no-nested-ternary
  return props.isUnavailable
    ? STEP_INDICATOR[ParticipantStepState.PENDING]
    : isStepAvailableForComplete(step)
    ? STEP_INDICATOR[ParticipantStepState.RUNNING]
    : STEP_INDICATOR[step.transitions[0].state];
};
